<template>
  <div class="header bg-gradient-success py-7">
    <div class="container">
      <div class="header-body text-center mb-6">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5 justify-content-center">
            <img
              class="w-75"
              src="/img/brand/logo-rectangle.svg"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          class="fill-default"
          points="2560 0 2560 100 0 100"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogoHeader',
}
</script>
